<template>
    <b-modal :id="uniqueId" :ref="uniqueId" @hidden="handleClose">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
            <h2 class="mx-auto my-auto">{{ $t('syndication.ajouter_porteurs_parts_moal_titre') }}</h2>
        </template>
        <template slot="default">
            <b-form>
                <div class="form-group">
                    <b-form-group :label="$t('syndication.type_saillie')">
                        <b-form-radio 
                            class="custom-control-inline"
                            name="saillie-type-radios" 
                            v-model="is_saillie_perso" 
                            :value="true"
                        >
                            {{ $t('syndication.saillie_perso') }}
                        </b-form-radio>
                        <b-form-radio 
                            class="custom-control-inline"
                            name="saillie-type-radios"
                            v-model="is_saillie_perso"
                            :value="false"
                        >
                            {{ $t('syndication.saillie_pool') }}
                        </b-form-radio>
                    </b-form-group>
                </div>
                <div class="form-group">
                    <label>{{ $t('syndication.type_facturation_part') }}</label>
                    <e-select
                        v-model="invoice_type"
                        id="invoicetype_id"
                        track-by="invoicetype_id"
                        label="invoicetype_labeltrad"
                        :selectedLabel="$t('global.selected_label')"
                        :options="syndic_invoice_types"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="loading"
                        :show-labels="true"
                    >
                        <template slot="option" slot-scope="{ option }">{{ $t(option.invoicetype_labeltrad) }}</template>
                        <template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicetype_labeltrad) }}</template>
                    </e-select>
                </div>
            </b-form>
        </template>

        <template slot="modal-footer" class="justify-content-center">
            <b-button variant="primary" @click="checkForm()">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                {{ $t('global.modifier') }}
            </b-button>
        </template>
    </b-modal>
</template>


<script type="text/javascript">
    import Gynecologie from "@/mixins/Gynecologie.js"
    import Syndic from "@/mixins/Syndic.js"

	export default {
        name: "EditMultiPartModal",
        mixins: [Gynecologie, Syndic],
        props: {
            submitForm: { type: Function, default: () => false },
			syndic_invoice_types: { type: Array, default: () => [] }
        },
        data() {
            return {
                loading: true,
                processing: false,
                parts_ids: [],
                is_saillie_perso: 1,
                invoice_type: null
            }
        },
        computed: {
            uniqueId() {
                return 'modal-edit-part-'+this._uid
            },
        },
		watch: {
			'syndic_invoice_types' (val) {
				this.invoice_type = val[0]
			}
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.invoice_type = this.syndic_invoice_types.length > 0 ? this.syndic_invoice_types[0] : null
                this.loading = false
            },

            checkForm() {
                this.submitForm({
                    syndicpart_ids: this.parts_ids,
                    is_saillie_perso: this.is_saillie_perso,
                    is_saillie_pool: !this.is_saillie_perso,
                    invoice_type: this.invoice_type.invoicetype_id
                })
            },

            openModal(parts) {
            	this.parts_ids = parts.map(part => part.saillie_part.syndicpart_id)
                this.is_saillie_perso = parts[0].saillie_part.syndicpart_is_perso === true
                this.invoice_type = parts[0].saillie_part.syndicpart_invoice_type
                this.$bvModal.show(this.uniqueId)
            },

            closeModal() {
                this.$bvModal.hide(this.uniqueId)
            },

            handleClose() {
                console.log("handleClose")
            }
        }
	}
</script>
